<template>
  <validation-observer
    v-if="order"
    ref="observer"
    v-slot="{ invalid, errors, validate }"
    tag="div"
  >
    <v-overlay
      v-model="showOverlay"
      style="z-index: 10000"
      class="d-flex justify-center align-center"
    >
      <cz-spinner style="z-index: 100001" color="white" :size="50" />
    </v-overlay>
    <v-card
      flat
      outlined
      rounded="lg"
      style="position: sticky; top: 110px; z-index: 101"
    >
      <v-stepper flat color="primary" v-model="orderCreationCurrentStep">
        <v-stepper-header>
          <template v-for="(step, index) in orderCreationSteps">
            <v-stepper-step :step="step.step" :key="step.step">
              {{ step.text }}
            </v-stepper-step>
            <v-divider
              v-if="index < orderCreationSteps.length - 1"
              :key="`${step.step}_divider`"
            />
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-card>
    <v-container
      class="pt-0 my-2 px-0 full-height d-flex"
      fluid
      style="padding-bottom: 80px; gap: 10px"
    >
      <v-row dense v-show="orderCreationCurrentStepId === 'order-details'">
        <v-col :lg="7" :md="6">
          <order-details
            v-model="order"
            :additional-documents.sync="additionalDocuments"
            @on-vendor-change="onVendorChange"
            @on-vendor-business-type-change="onVendorBusinessTypeChange"
            @on-vendor-invite="inviteUserDialog.show = true"
            :selected-vendor="selectedVendor"
            :vendor-business-type="vendorBusinessType"
            :loading-vendor="loadingVendor"
            :is-manual-creation-mode="isManualCreationMode"
            :read-only-mode="false"
            :vendor-vat="vendorVat"
            :system-tags="account.systemTags"
            create-mode
            :order-price-section-visible="isOrderPriceSectionVisible"
          />
        </v-col>
        <v-col :lg="5" :md="6">
          <order-document
            v-if="orderCreationCurrentStepId === 'order-details'"
            :order="order"
            :selected-vendor="selectedVendor"
            @change="onOrderDocumentChange"
            @generating="onDocumentGenerating"
            async
          />
        </v-col>
      </v-row>

      <order-items
        v-if="isOrderItemsStepVisible"
        v-show="orderCreationCurrentStepId === 'order-items'"
        v-model="order"
        :catalog-items="catalogItems"
        :vendor-vat="vendorVat"
        :read-only-mode="readOnlyMode"
        class="flex-grow-1"
      />
      <order-approvals
        v-show="orderCreationCurrentStepId === 'approval-workflow'"
        v-model="order"
        :account-workflows="accountWorkflows"
        :approvers="approvers"
        :current-user="currentUser"
        class="flex-grow-1"
      />

      <v-row dense v-if="orderCreationCurrentStepId === 'order-summary'">
        <v-col :lg="7" :md="6">
          <order-summery
            v-if="orderCreationCurrentStepId === 'order-summary'"
            :order="order"
            :sending="saving"
            :can-send-order="!invalid"
            @send="saveOrder(true)"
            :error-messages="collectObserverErrors(errors)"
          />
        </v-col>
        <v-col :lg="5" :md="6">
          <order-document
            v-if="order"
            :order="order"
            :selected-vendor="selectedVendor"
            @change="onOrderDocumentChange"
            async
          />
        </v-col>
      </v-row>
    </v-container>

    <cz-prompt-dialog
      v-if="promptDialog.show"
      v-model="promptDialog.show"
      v-bind="promptDialog"
      @done="onClosePromptDialog"
    />
    <v-footer
      fixed
      class="elevation-2"
      style="column-gap: 10px"
      color="white"
      :height="80"
    >
      <cz-button
        v-visible="orderCreationCurrentStepId !== 'order-summary'"
        class="mx-3"
        :title="$t('common.saveDraft')"
        large
        color="button"
        :dark="!saveDraftDisabled"
        :disabled="saveDraftDisabled"
        :icon-src="mdiContentSave"
        :icon-size="20"
        @click="saveOrder(false)"
        :loading="saving || generatingPdf"
        :width="150"
      />
      <v-spacer />

      <div class="d-flex" style="column-gap: 15px">
        <cz-button
          :title="$t('common.prev')"
          @click="orderCreationCurrentStep--"
          :loading="cancelling"
          text
          outlined
          large
          :disabled="orderCreationCurrentStep === 1"
          :icon-src="mdiChevronRight"
          :width="120"
          class="text-body-1 font-weight-semibold"
        />
        <v-divider vertical />
        <cz-button
          :title="$t('common.next')"
          @click="validate().then(onNext)"
          text
          large
          outlined
          :loading="cancelling"
          :disabled="!isNextEnabled"
          :icon-src="mdiChevronLeft"
          icon-location="append"
          :width="120"
          class="text-body-1 font-weight-semibold"
        />
      </div>

      <v-spacer />
      <span style="width: 150px" />
    </v-footer>

    <invite-user-dialog
      v-if="inviteUserDialog.show"
      v-model="inviteUserDialog.show"
      v-bind="inviteUserDialog"
      @close="inviteUserDialog.show = false"
      @on-invite="inviteUser"
    />
  </validation-observer>
</template>

<script>
import {
  createInvitation,
  getUserRoles,
  getVendors,
  updateOrder,
  uploadFiles,
  createOrder,
  createOrderDocument
} from '@/core/api';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { CzPromptDialog, CzButton, CzSpinner } from '@/components';
import cloneDeep from 'lodash.clonedeep';
import { parse, format } from 'date-fns';
import { typesMixin } from '@/shared/mixins';
import {
  mdiInformation,
  mdiInformationOutline,
  mdiHistory,
  mdiPencil,
  mdiEmailFastOutline,
  mdiUndo,
  mdiCancel,
  mdiClose,
  mdiSendClockOutline,
  mdiCheckAll,
  mdiDownload,
  mdiChevronLeft,
  mdiCloseCircleOutline,
  mdiChevronRight,
  mdiContentSave,
  mdiCheck,
  mdiDotsHorizontal,
  mdiDotsHorizontalCircleOutline,
  mdiDotsVertical
} from '@mdi/js';
import OrderStatus from '@/shared/types/OrderStatus';
import currencyTypes from '@/shared/types/CurrencyTypes';
import isEqual from 'lodash.isequal';
import InvitationType from '@/shared/types/InvitationType';
import { userHasRoleWithName } from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import { extractRolesFromWorkflow } from '@/shared/services/workflow.service';
import groupBy from 'lodash.groupby';
import { BusinessTypeVatAmount } from '@/shared/types/BusinessType';
import { VAT_PERCENT } from '@/shared/constants';
import { dataURItoBlob } from '@/shared/services/fileReader.service';
import ACTIVE_FEATURE from '@/shared/types/ActiveFeature';

export default {
  name: 'BuildOrderPage',
  components: {
    InviteUserDialog: () => import('../components/InviteUserDialog.vue'),
    OrderDetails: () => import('../components/order/OrderDetails.vue'),
    OrderItems: () => import('../components/order/OrderItems.vue'),
    OrderSummery: () => import('../components/order/OrderSummary.vue'),
    OrderApprovals: () => import('../components/order/OrderApprovals.vue'),
    OrderDocument: () => import('../components/order/OrderDocument.vue'),
    CzPromptDialog,
    CzButton,
    CzSpinner
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Object
    }
  },
  mixins: [typesMixin],
  created() {
    this.order = cloneDeep(this.value);
    this.loadOrderApprovers();
    this.selectedVendor = this.order.vendor;
    this.orderCopy = cloneDeep(this.order);
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId', 'currentUser']),
    ...mapFields('customer', [
      'currentOrdersTabId',
      'accountWorkflows',
      'catalogItems'
    ]),
    saveDraftDisabled() {
      return !this.orderChanged || this.generatingPdf;
    },
    readOnlyMode() {
      if (this.isManualCreationMode) {
        if (
          this.order?.status === OrderStatus.NEW ||
          this.order?.status === OrderStatus.NEW_PREDICTED
        ) {
          return false;
        } else if (
          this.order?.status === OrderStatus.RETURNED &&
          this.order?.createdByUserId === this.currentUser._id
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return (
          !userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER) ||
          (this.order?.status !== OrderStatus.NEW &&
            this.order?.status !== OrderStatus.NEW_PREDICTED)
        );
      }
    },
    businessType() {
      if (this.selectedVendor) {
        return this.selectedVendor.businessType;
      } else {
        return this.vendorBusinessType;
      }
    },
    vendorVat() {
      const val = BusinessTypeVatAmount[this.businessType];
      return val !== undefined ? val : VAT_PERCENT;
    },
    isManualCreationMode() {
      return this.order?.creationType === 'manual';
    },
    orderItemsFeatureEnabled() {
      return this.account?.activeFeatures.includes(
        ACTIVE_FEATURE.ORDER_CREATION_WITH_ITEMS
      );
    },
    isOrderPriceSectionVisible() {
      if (
        !this.isManualCreationMode ||
        this.order.documentType === 'order' ||
        // !Object.prototype.hasOwnProperty.call(this.order, 'orderItems') ||
        !this.orderItemsFeatureEnabled
      ) {
        return true;
      }

      return false;
    },
    orderCreationIsLastStep() {
      return this.orderCreationCurrentStep === this.orderCreationSteps.length;
    },
    isNextEnabled() {
      return !this.orderCreationIsLastStep;
    },
    orderCreationCurrentStepId() {
      return this.orderCreationSteps[this.orderCreationCurrentStep - 1]?.id;
    },
    isSendOrderButtonVisible() {
      if (this.isManualCreationMode) {
        if (
          (this.order?.status === OrderStatus.NEW ||
            this.order?.status === OrderStatus.NEW_PREDICTED) &&
          this.orderCreationCurrentStep === 2
        ) {
          return true;
        }
      }

      return (
        this.order?.status === OrderStatus.NEW ||
        this.order?.status === OrderStatus.NEW_PREDICTED
      );
    },
    isOrderItemsStepVisible() {
      return (
        this.orderItemsFeatureEnabled &&
        this.order?.documentType === 'order-items'
      );
    },
    orderCreationSteps() {
      if (this.isManualCreationMode) {
        const steps = [
          {
            text: this.$t('customer.orders.orderGeneralDetails'),
            id: 'order-details'
          },
          {
            text: this.$t('customer.orders.orderItems'),
            id: 'order-items'
          },
          {
            text: this.$t('customer.orders.orderApprovalWorkflow'),
            id: 'approval-workflow'
          },
          {
            text: this.$t('customer.orders.summery'),
            id: 'order-summary'
          }
        ];

        if (!this.isOrderItemsStepVisible) {
          steps.splice(1, 1);
        }

        steps.forEach((step, index) => {
          step.step = index + 1;
        });

        return steps;
      } else {
        return [
          {
            text: this.$t('customer.orders.orderGeneralDetails'),
            step: 1,
            id: 'order-details'
          },

          {
            text: this.$t('customer.orders.orderApprovalWorkflow'),
            step: 2,
            id: 'approval-workflow'
          },
          {
            text: this.$t('customer.orders.summery'),
            step: 3,
            id: 'order-summary'
          }
        ];
      }
    },

    orderChanged() {
      return (
        !isEqual(this.order, this.orderCopy) ||
        (this.hasAdditionalDocuments && this.isAdditionalDocumentsValid)
      );
    },
    hasAdditionalDocuments() {
      return this.additionalDocuments?.length > 0;
    },
    isAdditionalDocumentsValid() {
      if (!this.hasAdditionalDocuments) {
        return true;
      }

      return (
        this.additionalDocuments.filter(
          (item) => !item.title || !item.file || !item.type
        ).length === 0
      );
    }
  },
  data() {
    return {
      mdiEmailFastOutline,
      mdiHistory,
      mdiContentSave,
      mdiInformationOutline,
      mdiSendClockOutline,
      mdiDotsHorizontal,
      mdiDotsVertical,
      mdiCloseCircleOutline,
      mdiDotsHorizontalCircleOutline,
      mdiCancel,
      mdiClose,
      mdiChevronLeft,
      mdiChevronRight,
      mdiDownload,
      mdiPencil,
      mdiCheck,
      mdiInformation,
      mdiUndo,
      mdiCheckAll,
      currencyTypes,
      currentApprovalRoundStep: 1,
      generatingPdf: false,
      order: null,
      orderDocumentUri: null,
      showOverlay: false,
      additionalDocuments: [],
      orderCopy: null,
      selectedVendor: null,
      vendorBusinessType: '',
      loading: false,
      saving: false,
      cancelling: false,
      closing: false,
      loadingVendor: false,
      inviteUserDialog: {
        show: false,
        loading: false,
        allowedInvitationTypes: [InvitationType.VendorAdmin]
      },
      orderCreationCurrentStep: 1,
      orderTab: undefined,
      promptDialog: {
        show: false,
        title: this.$t('common.unableToSaveOrder'),
        titleColor: 'negative',
        state: 'error',
        message: null,
        hideCancelButton: true,
        doneButtonTitle: this.$t('common.close'),
        nextStepId: undefined
      },
      approvers: null
    };
  },
  methods: {
    format,
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('customer', ['loadAccountWorkflows', 'loadCatalogItems']),
    collectObserverErrors(errors) {
      const errorMessages = [];

      Object.values(errors).forEach((inlineErrors) => {
        inlineErrors.forEach((errorMessage) => {
          errorMessages.push(errorMessage);
        });
      });

      return errorMessages;
    },
    async loadOrderApprovers() {
      if (this.readOnlyMode && this.order?.authorizerUser) {
        this.approvers = [this.order.authorizerUser];
      } else {
        const roles = extractRolesFromWorkflow(this.accountWorkflows);
        const { data: userRoles } = await getUserRoles({
          accountId: this.accountId,
          role: { $in: roles },
          $populate: ['user']
        });

        this.approvers = groupBy(userRoles, 'role');
      }
    },

    async onNext() {
      this.orderCreationCurrentStep++;
    },
    async onVendorChange(value) {
      try {
        if (!value) {
          this.selectedVendor = null;
          return;
        }
        this.loadingVendor = true;
        const { data, total } = await getVendors({
          _id: value,
          $limit: 1
        });

        if (total > 0) {
          this.selectedVendor = data[0];
        } else {
          this.selectedVendor = null;
        }
      } finally {
        this.loadingVendor = false;
      }
    },
    onVendorBusinessTypeChange(value) {
      this.vendorBusinessType = value;
    },
    onOrderDocumentChange(documentUri) {
      this.orderDocumentUri = documentUri;
    },
    onDocumentGenerating(value) {
      this.generatingPdf = value;
    },
    onClosePromptDialog() {
      this.promptDialog.show = false;
      if (this.promptDialog.nextStepId) {
        const nextStepId = this.promptDialog.nextStepId;
        this.orderCreationCurrentStep = 0;
        setTimeout(() => {
          this.orderCreationCurrentStep = nextStepId;
        }, 250);
      }

      this.promptDialog.nextStepId = undefined;
    },
    async saveOrderDraft() {
      const _order = cloneDeep(this.order);

      try {
        this.saving = true;
        if (_order.orderItems?.length) {
          _order.orderItems.forEach((orderItem) => {
            if (orderItem.supplyDate) {
              orderItem.supplyDate = parse(
                orderItem.supplyDate,
                'yyyy-MM-dd',
                new Date()
              );
            }
          });
        }

        if (_order.issueDate) {
          _order.issueDate = parse(_order.issueDate, 'yyyy-MM-dd', new Date());
        }

        if (this.id === 'new') {
          delete _order.account;
          this.order = await createOrder(_order);

          const orderDocuments = await this.createOrderDocuments(
            this.additionalDocuments,
            this.order
          );

          if (orderDocuments) {
            const orderDocumentsIds =
              orderDocuments?.map((item) => item._id) || [];

            delete this.order.additionalDocumentsIds;

            this.order = await updateOrder(this.order._id, {
              $push: { additionalDocumentsIds: orderDocumentsIds },
              ...this.order
            });
          }

          this.$router.replace({
            name: 'order-build',
            params: {
              id: this.order._id
            }
          });
        } else {
          const orderDocuments = await this.createOrderDocuments(
            this.additionalDocuments,
            _order
          );
          const orderDocumentsIds =
            orderDocuments?.map((item) => item._id) || [];

          delete _order.additionalDocumentsIds;

          if (orderDocumentsIds?.length) {
            _order.$push = { additionalDocumentsIds: orderDocumentsIds };
          }

          this.order = await updateOrder(_order._id, _order);
        }

        if (this.order.issueDate) {
          this.order.issueDate = format(
            new Date(this.order.issueDate),
            'yyyy-MM-dd'
          );
        }

        if (this.order.orderItems?.length) {
          this.order.orderItems = this.order.orderItems.map((orderItem) => {
            return {
              ...orderItem,
              supplyDate: format(new Date(orderItem.supplyDate), 'yyyy-MM-dd')
            };
          });
        }

        this.showSuccessToastMessage(
          this.$t('customer.orders.orderUpdateSuccess')
        );

        this.orderCopy = cloneDeep(this.order);
        this.additionalDocuments = [];
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.saving = false;
      }
    },
    async createEmptyOrder(order) {
      try {
        this.showOverlay = true;
        this.order = await createOrder(order);
        this.orderCopy = cloneDeep(this.order);
      } finally {
        setTimeout(() => {
          this.showOverlay = false;
        }, 1000);
      }
    },
    async saveOrder(send = false, init = false) {
      if (this.generatingPdf) {
        return;
      }

      // if we want to send order we need to perform validation
      if (send) {
        const isValid = await this.isOrderValid();
        if (!isValid) {
          return;
        }
      }
      const _order = cloneDeep(this.order);

      if (send) {
        if (
          _order.status === OrderStatus.NEW ||
          _order.status === OrderStatus.NEW_PREDICTED ||
          _order.status === OrderStatus.RETURNED
        ) {
          // change order status to OPEN
          _order.status = OrderStatus.OPEN;
        }
      }

      if (_order.orderItems?.length) {
        _order.orderItems.forEach((orderItem) => {
          if (orderItem.supplyDate) {
            orderItem.supplyDate = parse(
              orderItem.supplyDate,
              'yyyy-MM-dd',
              new Date()
            );
          }
        });
      }
      _order.issueDate = parse(_order.issueDate, 'yyyy-MM-dd', new Date());

      try {
        this.saving = true;
        if (this.id === 'new') {
          this.order = await createOrder(_order);
          const orderDocuments = await this.createOrderDocuments(
            this.additionalDocuments,
            this.order
          );

          if (orderDocuments) {
            const orderDocumentsIds =
              orderDocuments?.map((item) => item._id) || [];

            delete this.order.additionalDocumentsIds;

            this.order = await updateOrder(this.order._id, {
              $push: { additionalDocumentsIds: orderDocumentsIds },
              ...this.order
            });
          }

          if (!send) {
            this.$router.replace({
              name: 'order-build',
              params: {
                id: this.order._id
              }
            });
          }
        } else {
          // upload document file if exists
          if (this.orderDocumentUri && send) {
            const blob = dataURItoBlob(this.orderDocumentUri);
            const file = new File([blob], 'order-doc.pdf', {
              type: 'application/pdf'
            });
            const [documentFile] = await uploadFiles([file], this.accountId);
            _order.orderDocumentFileId = documentFile._id;
          }

          const orderDocuments = await this.createOrderDocuments(
            this.additionalDocuments,
            this.order
          );

          if (orderDocuments) {
            const orderDocumentsIds =
              orderDocuments?.map((item) => item._id) || [];

            delete _order.additionalDocumentsIds;

            _order.$push = { additionalDocumentsIds: orderDocumentsIds };
          }

          // update the order
          this.order = await updateOrder(_order._id, {
            ..._order,
            accountId: this.accountId
          });
        }

        if (send) {
          this.orderCopy = cloneDeep(this.order);
          setTimeout(() => {
            this.$router.replace({
              name: 'customer-orders',
              params: {
                selectedTabId:
                  this.order.status === OrderStatus.PENDING_APPROVAL
                    ? 'pending-approval'
                    : 'active-orders'
              }
            });
          }, 100);
        } else {
          if (this.order.issueDate) {
            this.order.issueDate = format(
              new Date(this.order.issueDate),
              'yyyy-MM-dd'
            );
          }

          if (this.order.orderItems?.length) {
            this.order.orderItems = this.order.orderItems.map((orderItem) => {
              return {
                ...orderItem,
                supplyDate: format(new Date(orderItem.supplyDate), 'yyyy-MM-dd')
              };
            });
          }
        }

        if (!init) {
          this.showSuccessToastMessage(
            this.$t('customer.orders.orderUpdateSuccess')
          );
        }

        this.orderCopy = cloneDeep(this.order);
        this.$emit('order-saved', this.order);
        this.additionalDocuments = [];
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.saving = false;
      }
    },
    async createOrderDocuments(documents, order) {
      if (!documents?.length) {
        return null;
      }

      const files = documents.map((item) => item.file);
      const submissionDocumentsFiles = await uploadFiles(files, this.accountId);

      const orderDocuments = submissionDocumentsFiles.map((file, index) => {
        return {
          orderId: order._id,
          accountId: this.accountId,
          fileId: file._id,
          title: documents[index]?.title || '',
          type: documents[index].type
        };
      });

      const orderDocRes = await createOrderDocument(orderDocuments, {
        accountId: this.accountId
      });

      return orderDocRes;
    },
    async isOrderValid() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }

      if (!this.validateOrderAmountBeforeSave()) {
        return false;
      }

      return true;
    },
    validateOrderAmountBeforeSave() {
      let netAndVat =
        parseFloat(this.order.netAmount) + parseFloat(this.order.vatAmount);
      netAndVat = parseFloat(netAndVat).toFixed(2);

      if (this.order.netAmount <= 0) {
        this.promptDialog.message = this.$t('common.netAmountZero');
        this.promptDialog.show = true;
        this.promptDialog.nextStepId = 1;
        // this.orderCreationCurrentStep = 1;
        return false;
      }

      if (this.vendorVat === 0 && parseFloat(this.order.vatAmount) > 0) {
        this.promptDialog.message = this.$t('common.vatExempt');
        this.promptDialog.show = true;
        this.promptDialog.nextStepId = 1;
        // this.orderCreationCurrentStep = 0;
        return false;
      }

      if (this.vendorVat > 0 && parseFloat(this.order.vatAmount) === 0) {
        this.promptDialog.message = this.$t('common.vatCannotBeZero');
        this.promptDialog.show = true;
        this.promptDialog.nextStepId = 1;
        // this.orderCreationCurrentStep = 1;
        return false;
      }

      const netVatSum = this.order.netAmount * parseFloat(this.vendorVat);

      let vatSub = parseFloat(this.order.vatAmount - netVatSum).toFixed(2);
      vatSub = parseFloat(vatSub);

      // validate that order vat and total amount are correct
      if (vatSub > 0.01 || vatSub < -0.01) {
        this.promptDialog.message = this.$t('common.invalidCalcVat');
        this.promptDialog.show = true;
        this.promptDialog.nextStepId = 1;
        // this.orderCreationCurrentStep = 1;
        return false;
      }

      const sub = parseFloat(this.order.totalAmount) - parseFloat(netAndVat);

      // allow total amount tollerance for up to 0.1
      if (sub > 1 || sub < -1) {
        this.promptDialog.message = this.$t('common.invalidTotalAmountCalc');
        this.promptDialog.show = true;
        this.promptDialog.nextStepId = 1;
        // this.orderCreationCurrentStep = 1;
        return false;
      }

      return true;
    },

    async inviteUser(data) {
      try {
        this.inviteUserDialog.loading = true;
        await createInvitation({
          inviteeEmail: data.email,
          assignedRoles: [data.role.role],
          invitationType: data.role.invitationType,
          accountId: this.accountId
        });
        this.showSuccessToastMessage(
          this.$t('invite.inviteSentSuccess', [data.email])
        );
        this.inviteUserDialog.show = false;
      } finally {
        this.inviteUserDialog.loading = false;
      }
    }
  },
  watch: {
    orderCreationCurrentStepId: {
      async handler(value) {
        if (
          this.id === 'new' &&
          !this.order?._id &&
          value === 'order-summary'
        ) {
          const isValid = await this.isOrderValid();
          if (!isValid) {
            return;
          }
          try {
            setTimeout(() => {
              this.showOverlay = true;
              setTimeout(async () => {
                await this.saveOrder(false, true);
                setTimeout(async () => {
                  this.showOverlay = false;
                }, 1500);
              }, 1000);
            }, 0);
          } finally {
          }
        }
      }
    },
    order: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    },
    orderCopy: {
      handler(value) {
        this.$emit('update:orderCopy', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-height {
  // max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.doc-height {
  overflow: auto;
  // aspect-ratio: 1.25 / 1.414 !important;
  // scale: 1.2;
}
</style>
